import request from "@/utils/request";

export function getInvoiceList(data) {
  // console.log(data)
  return request({
    url: "/mgr/invoices/queryInvoicesList",
    method: "post",
    data,
  });
}

export function getInvoiceById(data) {
  // console.log(data)
  return request({
    url: "/mgr/invoices/queryInvoicesDetailList",
    method: "post",
    data,
  });
}

export function getInvoicesItem(data) {
  return request({
    url: "/mgr/invoices/queryInvoicesItemsList",
    method: "post",
    data,
  });
}

export function getInvoicesHistoryAndEvents(data) {
  return request({
    url: "/mgr/invoices/queryHistoryAndEvents",
    method: "post",
    data,
  });
}

export function getInvoicesBillingAndDelivery(data) {
  return request({
    url: "/mgr/invoices/queryInvoicesBillingAndDeliveryList",
    method: "post",
    data,
  });
}

export function getInvoicesPayment(data) {
  return request({
    url: "/mgr/invoices/queryPaymentList",
    method: "post",
    data,
  });
}

export function getItemsFromOrders(data) {
  return request({
    url: "/mgr/invoices/searchItems",
    method: "get",
    params: data,
  });
}

export function saveInvoicesInfo(data) {
  return request({
    url: "/mgr/invoices/saveInvoicesInfo",
    method: "post",
    data,
  });
}

export function saveInvoicePayment(data) {
  return request({
    url: "/mgr/invoices/savePayment",
    method: "post",
    data,
  });
}

export function printInvoicePage(id) {
  return request({
    url: `/mgr/invoices/pdf/invoice/${id}`,
    method: "get",
  });
}

export function printPickingSlip(id) {
  return request({
    url: `/mgr/invoices/pdf/pickingSlip/${id}`,
    method: "get",
  });
}
export function convertQuoteToInvoice(data) {
  return request({
    url: `/mgr/invoices/convertQuoteToInvoice`,
    method: "post",
    data,
  });
}

export function cloneQuote(data) {
  return request({
    url: `/mgr/invoices/cloneQuote`,
    method: "post",
    data,
  });
}

export function cancelQuote(data) {
  return request({
    url: `/mgr/invoices/cancelQuote`,
    method: "post",
    data,
  });
}

export function finalizedInvoice(data) {
  return request({
    url: `/mgr/invoices/finalizedInvoice`,
    method: "post",
    data,
  });
}

export function addGeneralNotes(data) {
  return request({
    url: `/mgr/invoices/addNotesAndInfo`,
    method: "post",
    data,
  });
}

export function addInvoiceNotes(data) {
  return request({
    url: `/mgr/invoices/addInvoiceNotes`,
    method: "post",
    data,
  });
}

export function getPartDetail(data) {
  return request({
    url: "/mgr/invoices/queryParts",
    method: "get",
    params: data,
  });
}

export function saveCreditNotes(data) {
  return request({
    url: `/mgr/invoices/saveCreditNotes`,
    method: "post",
    data,
  });
}
export function queryPaymentList(data) {
  return request({
    url: "/mgr/invoices/queryPaymentList",
    method: "get",
    params: data,
  });
}
export function deleteCreditAndPayment(data) {
  return request({
    url: "/mgr/invoices/deleteCreditAndPayment",
    method: "delete",
    params: data,
  });
}
export function deleteInvoiceItem(invoiceItemId) {
  return request({
    url: `/mgr/invoices/deleteInvoiceItem/${invoiceItemId}`,
    method: "delete",
  });
}
export function checkItemSoldOrNot(tagNoList) {
  return request({
    url: `/mgr/invoices/checkItemSoldOrNot/${tagNoList}`,
    method: "post",
  });
}

export function reOpenInvoice(invoiceID) {
  return request({
    url: `/mgr/invoices/reOpen/${invoiceID}`,
    method: "post",
  });
}
