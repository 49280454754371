import request from '@/utils/request'

export function getUserList(data) {
  return request({
    url: '/user/page',
    method: 'post',
    data
  })
}

export function createUser(data) {
  return request({
    url: '/user/save',
    method: 'post',
    data
  })
}

export function updateUser(data) {
  return request({
    url: `/user/manageUserProfile/${data.id}`,
    method: 'post',
    data
  })
}

export function getPickUserList() {
  return request({
    url: '/user/getPickUserList',
    method: 'get'
  })
}
export function getSalePersonList(data) {
  return request({
    url: '/user/getSalePersonList',
    method: 'get',
    params: data
  })
}

export function saveDefaultExpenseAndPayment(data) {
  return request({
    url: '/user/saveDefaultExpenseAndPayment',
    method: 'post',
    data
  })
}