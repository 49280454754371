import request from "@/utils/request";

export function getPoList(data) {
  return request({
    url: "/mgr/purchaseOrder/purchaseOrderList",
    method: "post",
    data,
  });
}

export function getPartDetail(data) {
  return request({
    url: "/mgr/purchaseOrder/queryParts",
    method: "get",
    params: data,
  });
}

export function updatePurchaseOrderDetail(data) {
  return request({
    url: "/mgr/purchaseOrder/save",
    method: "post",
    data,
  });
}

export function getPurchaseOrderDetail(data) {
  return request({
    url: "/mgr/purchaseOrder/getPurchaseOrderDetail",
    method: "get",
    params: data,
  });
}

export function getPODetailItems(data) {
  return request({
    url: `/mgr/purchaseOrder/getPurchaseOrderItems`,
    method: "get",
    params: data,
  });
}

export function commitToInventory(data) {
  return request({
    url: "/mgr/purchaseOrder/commit",
    method: "post",
    data,
  });
}

export function getPoPaymentList(data) {
  return request({
    url: "/mgr/purchaseOrder/queryPOPaymentList",
    method: "get",
    params: data,
  });
}

export function updatePoPayment(data) {
  return request({
    url: "/mgr/purchaseOrder/updatePOPaymentList",
    method: "post",
    data,
  });
}

export function getHistoryAndEventstList(data) {
  return request({
    url: "/mgr/purchaseOrder/queryHistoryAndEvents",
    method: "get",
    params: data,
  });
}

export function searchPO(data) {
  return request({
    url: "/mgr/purchaseOrder/getPoNumList",
    method: "get",
    params: data,
  });
}

export function getPoItems(data) {
  return request({
    url: "/mgr/purchaseOrder/getItems",
    method: "get",
    params: data,
  });
}

export function getPoItemPictures(data) {
  return request({
    url: "/mgr/purchaseOrder/pictures",
    method: "get",
    params: data,
  });
}

export function queryInsertPartsByPoNum(data) {
  return request({
    url: "/mgr/purchaseOrder/queryInsertParts",
    method: "get",
    params: data,
  });
}
export function addPONotes(data) {
  return request({
    url: "/mgr/purchaseOrder/addPONotes",
    method: "post",
    data,
  });
}
export function addPONotesAndInfo(data) {
  return request({
    url: "/mgr/purchaseOrder/addNotesAndInfo",
    method: "post",
    data,
  });
}
export function uploadFile(data, poId) {
  return request({
    url: `/mgr/purchaseOrder/uploadFile/${poId}`,
    method: "post",
    data,
  });
}

export function transferItemsPO(data) {
  return request({
    url: `/mgr/purchaseOrder/movePoItems`,
    method: "post",
    data,
  });
}
export function finalizePO(data) {
  return request({
    url: `/mgr/purchaseOrder/finalizePurchaseOrder`,
    method: "post",
    data,
  });
}
export function createXeroBill(data) {
  return request({
    url: "/mgr/purchaseOrder/createXeroBill",
    method: "post",
    data,
  });
}
export function saveCreditNotes(data) {
  return request({
    url: `/mgr/purchaseOrder/saveCreditNotes`,
    method: "post",
    data,
  });
}

export function getPOCreditNotes(poId) {
  return request({
    url: `/mgr/purchaseOrder/queryCreditNotes/${poId}`,
    method: "get",
  });
}

export function removeCreditNotes(id) {
  return request({
    url: `/mgr/purchaseOrder/removeCreditNotes/${id}`,
    method: "delete",
  });
}

export function searchPurchaseOrderItemByListTag(tagNoList) {
  return request({
    url: `/mgr/purchaseOrder/searchPurchaseOrderItem`,
    method: "get",
    params: { tagNoList },
  });
}
export function editChildrenItem(data) {
  return request({
    url: `/mgr/purchaseOrder/editChildrenItem`,
    method: "post",
    data,
  });
}
export function editParentItem(data) {
  return request({
    url: `/mgr/purchaseOrder/editParentItem`,
    method: "post",
    data,
  });
}
export function editNonCommitItem(data) {
  return request({
    url: `/mgr/purchaseOrder/editUnCommitItem`,
    method: "post",
    data,
  });
}
export function removePOItem(id) {
  return request({
    url: `/mgr/purchaseOrder/deletePoItem/${id}`,
    method: "delete",
  });
}
