import { apiService } from "../../services/api-service";
import { setToken, getToken, removeToken, parseJwt } from "../../utils/auth";
import moment from "moment";

let timer = "";

const state = () => {
  return {
    token: getToken("ezipart-token") || null,
    userId: Number(getToken("ezipart-userId")) || null,
    userName: localStorage.getItem("ezipart-userName", "") || null,
    active: localStorage.getItem("user-active", "") || null,
    postCode: localStorage.getItem("user-postCode", "") || null,
    streetAddress: localStorage.getItem("user-address", "") || null,
    suburb: localStorage.getItem("user-suburb", "") || null,
    permission: localStorage.getItem("user-permission") || [],
    role: localStorage.getItem("user-role") || null,
    isConnectedToXero: false,
    isLogout: false,
  };
};

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  SET_USERNAME: (state, name) => {
    state.userName = name;
  },
  SET_USERID: (state, userId) => {
    state.userId = userId;
  },
  SET_USER_ACTIVE: (state, userActive) => {
    state.userInfo = userActive;
  },
  SET_USER_POSTCODE: (state, postCode) => {
    state.userInfo = postCode;
  },
  SET_USER_ADDRESS: (state, streetAddress) => {
    state.userInfo = streetAddress;
  },
  SET_USER_SUBURB: (state, suburb) => {
    state.userInfo = suburb;
  },
  SET_USER_PERMISSION: (state, permission) => {
    state.permission = permission;
  },
  SET_USER_ROLE: (state, role) => {
    state.role = role;
  },
  SET_USER_CONNECTED: (state, connected) => {
    state.isConnectedToXero = connected;
  },
  SET_USER_LOGIN: (state) => {
    state.isLogout = false;
  },
  SET_USER_LOGOUT: (state) => {
    state.isLogout = true;
  },
};

const actions = {
  loginHandler({ commit, dispatch }, userInfo) {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await apiService.auth.login(userInfo);
        console.log("result login", result);

        if (result) {
          const { code, data, msg } = result;
          if (code === 200) {
            commit("SET_USER_LOGIN");
            commit("SET_TOKEN", data.accessToken);
            commit("SET_USERNAME", data.userInfo.userName);
            commit("SET_USERID", data.userInfo.userID);
            commit("SET_USER_ACTIVE", data.userInfo.active);
            commit("SET_USER_POSTCODE", data.userInfo.postCode);
            commit("SET_USER_ADDRESS", data.userInfo.streetAddress);
            commit("SET_USER_SUBURB", data.userInfo.suburb);
            commit("SET_USER_PERMISSION", data.userInfo.permission);
            commit("SET_USER_ROLE", data.userInfo.role);
            setToken("ezipart-token", data.accessToken);
            setToken("ezipart-userId", data.userInfo.userID);
            localStorage.setItem("ezipart-userName", data.userInfo.userName);
            localStorage.setItem("user-active", data.userInfo.active);
            localStorage.setItem("user-postCode", data.userInfo.postCode);
            localStorage.setItem("user-address", data.userInfo.streetAddress);
            localStorage.setItem("user-suburb", data.userInfo.suburb);
            localStorage.setItem("user-permission", data.userInfo.permission);
            localStorage.setItem("user-role", data.userInfo.role);

            // token
            const currentTime = moment();
            const token = data.accessToken.split(" ")[1];
            const decodedToken = parseJwt(token);
            const expirationTime = moment
              .unix(decodedToken.exp)
              .diff(currentTime, "milliseconds");
            timer = setTimeout(() => {
              dispatch("logoutHandler");
            }, expirationTime);

            resolve({ access: true, msg });
          } else {
            reject("Login Fail");
          }
        } else {
          reject("Login Fail.");
        }
      } catch (err) {
        reject(err.message);
      }
    });
  },

  logoutHandler({ commit }) {
    localStorage.removeItem("tabs-va");
    localStorage.removeItem("tabs-vehicle");
    localStorage.removeItem("tabs-invoice");
    localStorage.removeItem("tabs-po");
    localStorage.removeItem("latestPages");

    removeToken("ezipart-token");
    removeToken("ezipart-userId");
    if (timer) {
      clearTimeout(timer);
    }
    commit("SET_USER_LOGOUT");
  },

  async checkConnectionToXero({ commit }) {
    try {
      const result = await apiService.setting.checkConnectedXeroAccount();
      if (result.code === 200) {
        commit("SET_USER_CONNECTED", result.data);
      }
    } catch (e) {
      console.log(e);
    }
  },
};
const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
