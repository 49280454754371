import request from "@/utils/request";

export function getList(data) {
  // console.log(data)
  return request({
    url: "/mgr/contactManage/queryContactList",
    method: "post",
    data,
  });
}

export const getCustomerDetail = (debtorID) => {
  return request({
    url: "/mgr/contactManage/getContactsInfo",
    method: "post",
    data: { debtorID },
  });
};

export const updateCustomerDetail = (data) => {
  return request({
    url: "/mgr/contactManage/modifyContactInfo",
    method: "post",
    data,
  });
};

export const updateContactPerosonDetail = (data) => {
  return request({
    url: "/mgr/contactManage/modifyContactContacts",
    method: "post",
    data,
  });
};

export const addContactInfo = (data) => {
  return request({
    url: "/mgr/contactManage/addContactInfo",
    method: "post",
    data,
  });
};

export const updateContactBankInfo = (data) => {
  return request({
    url: "/mgr/contactManage/updateContactBank",
    method: "post",
    data,
  });
};

export const addContactBankInfo = (data) => {
  return request({
    url: "/mgr/contactManage/addContactBank",
    method: "post",
    data,
  });
};

export const deleteContactBankInfo = (data) => {
  return request({
    url: "/mgr/contactManage/deleteContactBank",
    method: "post",
    data,
  });
};

export const deleteContactInfo = (data) => {
  return request({
    url: "/mgr/contactManage/removeContactContacts",
    method: "post",
    data,
  });
};

export const getHistoryList = (data) => {
  return request({
    url: "/mgr/contactManage/getContactsHistoryAndEventsList",
    method: "post",
    data,
  });
};

export const getStatement = (data) => {
  return request({
    url: "/mgr/contactManage/getStatement",
    method: "get",
    params: data,
  });
};

export const addNotes = (data) => {
  return request({
    url: "/mgr/contactManage/addNotes",
    method: "post",
    data,
  });
};

export const getCreditList = (data) => {
  return request({
    url: "/mgr/contactManage/getCreditList",
    method: "get",
    params: data,
  });
};

export const applyCredit = (data) => {
  return request({
    url: "/mgr/contactManage/applyCredit",
    method: "post",
    data,
  });
};

export const addPersonContactInfo = (data) => {
  return request({
    url: "/mgr/contactManage/addContactContactsInfo",
    method: "post",
    data,
  });
};
export const removeContact = (data) => {
  return request({
    url: "/mgr/contactManage/removeContact",
    method: "post",
    data,
  });
};

export const setMainContact = (data) => {
  return request({
    url: "/mgr/contactManage/saveMainContact",
    method: "post",
    data,
  });
};
