import router from "@/router";
import moment from "moment";

const state = () => {
  return {
    inventory: {
      filterData: {
        makeId: "",
        modelId: "",
        seriesId: "",
        search: "",
      },
      isFiltered: false,
      isLoading: false,
    },
    threshold: {
      filterData: {
        makeId: "",
        modelId: "",
        seriesId: "",
        search: "",
      },
      isFiltered: false,
      isLoading: false,
    },
    backOrder: {
      filterData: {
        makeId: "",
        modelId: "",
        seriesId: "",
        search: "",
      },
      isFiltered: false,
      isLoading: false,
    },
    listPrice: {
      filterData: {
        selectedDates: [
          moment().format("YYYY-MM-DD"),
          moment().add(1, "days").format("YYYY-MM-DD"),
        ],
        makeId: "",
        modelId: "",
        seriesId: "",
        search: "",
      },
      showDatePicker: true,
      isFiltered: false,
      isLoading: false,
    },
    makeList: [],
  };
};

const mutations = {
  SET_SELECTED_FILTER: (state, { moduleName, filterData }) => {
    state[moduleName].filterData = filterData;
  },
  SET_IS_FILTERED: (state, { moduleName, payload }) => {
    state[moduleName].isFiltered = payload;
  },
  SET_CLEAR_FILTER: (state, { moduleName, payload }) => {
    state[moduleName].filterData = payload;
  },
  SET_MAKE_LIST: (state, payload) => {
    state.makeList = payload;
  },
  SET_SEARCH_LOADING: (state, { moduleName, payload }) => {
    state[moduleName].isLoading = payload;
  },

  // listPrice
  SET_SELECTED_PRICE_LIST_DATE: (state, payload) => {
    state.listPrice.selectedDates = payload;
  },
  SET_SHOW_DATE_PICKER: (state, { moduleName, payload }) => {
    state[moduleName].showDatePicker = payload;
  },
};

const actions = {
  async setMakeList({ commit }, apiMake) {
    try {
      const result = await apiMake;

      const { code, data } = result;

      if (code === 200) {
        commit("SET_MAKE_LIST", data);
      }
    } catch (error) {
      throw new Error(error);
    }
  },
  setClearFilter({ state, commit }, { moduleName, isSearch }) {
    let payload = {
      makeId: "",
      modelId: "",
      seriesId: "",
      search: "",
    };
    if (moduleName === "listPrice" && !isSearch) {
      payload = {
        ...payload,
        selectedDates: [
          moment().format("YYYY-MM-DD"),
          moment().add(1, "days").format("YYYY-MM-DD"),
        ],
      };
    } else if (moduleName === "listPrice" && isSearch) {
      payload = {
        ...payload,
        selectedDates: state.listPrice.filterData.selectedDates,
      };
    }
    commit("SET_CLEAR_FILTER", { moduleName, payload });
  },
  async globalSearchHandler(
    { state, commit, dispatch },
    { listWords, descWords, apiModel, apiSeries, moduleName }
  ) {
    // ,
    commit("SET_SEARCH_LOADING", { moduleName, payload: true });
    if (
      router.currentRoute.name !== "inventorylist" &&
      moduleName === "inventory"
    ) {
      router.push({ name: "inventorylist" });
    }
    let listModel = [];
    let listSeries = [];
    const makeIdSelected = state[moduleName].filterData.makeId;
    const modelIdSelected = state[moduleName].filterData.modelId;

    if (listWords.length > 0) {
      if (!makeIdSelected) {
        const make = state.makeList.find((make) =>
          make.name
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(listWords[0].toLowerCase())
        );
        if (!make) {
          await dispatch("setClearFilter", { moduleName, isSearch: true });
          commit("SET_SEARCH_LOADING", { moduleName, payload: false });
          commit("SET_SELECTED_FILTER", {
            moduleName,
            filterData: {
              ...state[moduleName].filterData,
              search: listWords.join(" "),
            },
          });
          dispatch("setIsFiltered", { moduleName, payload: true });
          return;
        }
        await dispatch("setClearFilter", { moduleName, isSearch: true });
        if (descWords.length > 0) {
          commit("SET_SELECTED_FILTER", {
            moduleName,
            filterData: {
              ...state[moduleName].filterData,
              search: descWords[0],
            },
          });
        }
        commit("SET_SELECTED_FILTER", {
          moduleName,
          filterData: { ...state[moduleName].filterData, makeId: make.id },
        });
        if (listWords[1]) {
          const modelResult = await apiModel({ makeId: make.id });
          listModel = modelResult.data;
          const model = listModel.find((model) =>
            model.name
              .toLowerCase()
              .replace(/\s+/g, "")
              .includes(listWords[1].toLowerCase())
          );
          if (!model) {
            commit("SET_SEARCH_LOADING", { moduleName, payload: false });
            alert(`Model ${listWords[1]} not exits in ${make.name}`);
            return;
          }
          commit("SET_SELECTED_FILTER", {
            moduleName,
            filterData: { ...state[moduleName].filterData, modelId: model.id },
          });
          if (listWords[2]) {
            const seriesResult = await apiSeries({
              makeId: make.id,
              modelId: model.id,
            });
            listSeries = seriesResult.data;
            const series = listSeries.find((series) =>
              series.name
                .toLowerCase()
                .replace(/\s+/g, "")
                .includes(listWords[2].toLowerCase())
            );
            if (!series) {
              commit("SET_SEARCH_LOADING", { moduleName, payload: false });
              alert(
                `Series ${listWords[2]} not exits in ${make.name}-${model.name}`
              );
              return;
            }
            commit("SET_SELECTED_FILTER", {
              moduleName,
              filterData: {
                ...state[moduleName].filterData,
                seriesId: series.id,
              },
            });
            dispatch("setIsFiltered", { moduleName, payload: true });
          } else {
            dispatch("setIsFiltered", { moduleName, payload: true });
          }
        } else {
          dispatch("setIsFiltered", { moduleName, payload: true });
        }
      } else {
        if (!modelIdSelected) {
          const modelResult = await apiModel({ makeId: makeIdSelected });
          listModel = modelResult.data;
          const model = listModel.find((model) =>
            model.name
              .toLowerCase()
              .replace(/\s+/g, "")
              .includes(listWords[0].toLowerCase())
          );
          if (!model) {
            // alert(`Model ${listWords[0]} not exits in ${make.name}`);
            await dispatch("setClearFilter", { moduleName, isSearch: true });
            await dispatch("globalSearchHandler", {
              listWords,
              descWords,
              apiModel,
              apiSeries,
              moduleName: moduleName,
            });
            return;
          }
          commit("SET_SELECTED_FILTER", {
            moduleName,
            filterData: { ...state[moduleName].filterData, modelId: model.id },
          });
          if (listWords[1]) {
            const seriesResult = await apiSeries({
              makeId: makeIdSelected,
              modelId: model.id,
            });
            listSeries = seriesResult.data;
            const series = listSeries.find((series) =>
              series.name
                .toLowerCase()
                .replace(/\s+/g, "")
                .includes(listWords[1].toLowerCase())
            );
            if (!series) {
              await dispatch("setClearFilter", { moduleName, isSearch: true });
              await dispatch("globalSearchHandler", {
                listWords,
                descWords,
                apiModel,
                apiSeries,
                moduleName: moduleName,
              });
              return;
            }
            commit("SET_SELECTED_FILTER", {
              moduleName,
              filterData: {
                ...state[moduleName].filterData,
                seriesId: series.id,
              },
            });
            dispatch("setIsFiltered", { moduleName, payload: true });
          } else {
            dispatch("setIsFiltered", { moduleName, payload: true });
          }
        } else {
          if (listWords[0]) {
            const seriesResult = await apiSeries({
              makeId: makeIdSelected,
              modelId: modelIdSelected,
            });
            listSeries = seriesResult.data;
            const series = listSeries.find((series) =>
              series.name
                .toLowerCase()
                .replace(/\s+/g, "")
                .includes(listWords[0].toLowerCase())
            );
            if (!series) {
              await dispatch("setClearFilter", { moduleName, isSearch: true });
              await dispatch("globalSearchHandler", {
                listWords,
                descWords,
                apiModel,
                apiSeries,
                moduleName: moduleName,
              });
              return;
            }
            commit("SET_SELECTED_FILTER", {
              moduleName,
              filterData: {
                ...state[moduleName].filterData,
                seriesId: series.id,
              },
            });
            dispatch("setIsFiltered", { moduleName, payload: true });
          }
        }
      }
    }

    commit("SET_SEARCH_LOADING", { moduleName, payload: false });
  },
  setIsFiltered({ commit }, { moduleName, payload }) {
    commit("SET_IS_FILTERED", { moduleName, payload });
  },

  // list price
  setSelectedPriceListDate({ commit }, payload) {
    commit("SET_SELECTED_PRICE_LIST_DATE", payload);
  },
  setShowDatePicker({ commit }, { moduleName, payload }) {
    commit("SET_SHOW_DATE_PICKER", { moduleName, payload });
  },
};

const getters = {
  getSelectedFilter: (state) => (moduleName) => state[moduleName].filterData,
  getIsFiltered: (state) => (moduleName) => state[moduleName].isFiltered,
  getMakeList(state) {
    return state.makeList;
  },
  getSearchLoading: (state) => (moduleName) => state[moduleName].isLoading,

  // list price
  getShowDatePicker: (state) => (moduleName) =>
    state[moduleName].showDatePicker,
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
