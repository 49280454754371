import request from "@/utils/request";

export function getOtherChargesList(data) {
  return request({
    url: "/mgr/otherCharges/listOtherCharges",
    method: "get",
    params: data,
  });
}

export function createOrUpdateOtherCharges(data) {
  return request({
    url: "/mgr/otherCharges/createOrUpdateOtherCharges",
    method: "post",
    data,
  });
}

export function getDetailOtherCharges(data) {
  return request({
    url: `/mgr/otherCharges/getDetailOtherCharges/${data}`,
    method: "get",
  });
}