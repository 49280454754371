import request from "@/utils/request";

export function updatePart(data) {
  // console.log(data)
  return request({
    url: "/mgr/partNames/savePartName",
    method: "post",
    data,
  });
}

export function deletePart(id) {
  // console.log(data)
  return request({
    url: `/mgr/partNames/removePart/${id}`,
    method: "delete",
  });
}

export function getListPartsPrice(data) {
  return request({
    url: `/mgr/partNames/queryPartsPriceList`,
    method: "get",
    params: data,
  });
}

export function updatePartsPrice(data) {
  return request({
    url: `/mgr/partNames/updatePartPrice`,
    method: "post",
    data: data,
  });
}

export function getListPartNo(data) {
  return request({
    url: `/mgr/partNames/queryPartNo`,
    method: "get",
    params: data,
  });
}

export function getListPartNoItems(data) {
  return request({
    url: `/mgr/partNames/queryPartNoList`,
    method: "post",
    data: data,
  });
}

export function updatePartNo(data) {
  return request({
    url: `/mgr/partNames/updatePartNo`,
    method: "post",
    data: data,
  });
}
