/**
 * @typedef {import('axios').AxiosInstance} AxiosInstance
 */

export class InvoiceService {
  /**
   * @param {AxiosInstance} axios
   */
  constructor(axios) {
    this.axios = axios;
  }

  async getInvoiceList(data) {
    const result = await this.axios.post(
      "/mgr/invoices/queryInvoicesList",
      data
    );
    return result.data;
  }

  async checkItemSold(data) {
    const result = await this.axios.get(
      `/mgr/invoices/checkItemSoldOrNot/${data}`
    );
    return result.data;
  }

  async replacePart(partNo, partDesc) {
    const result = await this.axios.get(
      `/mgr/invoices/replacePart?partNo=${partNo}&partDesc=${partDesc}`,
    );
    return result.data;
  }
  async generateInvoicePdf(invoiceId) {
    const result = await this.axios.get(
      `/mgr/invoices/generateInvoicePdf/${invoiceId}`
    )
    return result.data;
  }
  async generateCreditNotesPdf(invoiceId) {
    const result = await this.axios.get(
        `/mgr/invoices/generateCreditNotesPdf/${invoiceId}`
    )
    return result.data;
  }
  async sendEmailInvoice(data) {
    const result = await this.axios.post(
      "/mgr/invoices/sendMail/",
      data
    );
    return result.data;
  }

  async getCurrentQuote(pageNum, pageSize, search) {
    const result = await this.axios.get(
      `/mgr/invoices/getCurrentQuote?pageNum=${pageNum}&pageSize=${pageSize}&search=${search}`
    );
    return result.data;
  }

  async saveInvoicesInfo(data) {
    const result = await this.axios.get("/mgr/invoices/saveInvoicesInfo", data);
    return result.data;
  }
}
