import * as customer from "./customer";
import * as common from "./common";
import * as purchaseOrder from "./purchaseOrder";
import * as vehicleAcquisition from "./vehicleAcquisitions";
import * as inventory from "./inventory";
import * as invoice from "./invoice";
import * as user from "./user";
import * as login from "./login";
import * as accountTerms from "./accountTerms";
import * as otherCharge from "./otherCharge";
import * as role from "./role";
import * as xero from "./xero";
import * as saleReport from "./saleReport";
import * as catalog from "./catalogManagement";
import * as partManagement from "./partManagement";
import * as superAdminDashboard from "./adminDashboard";
import * as priceList from "./searchPriceList";
import * as backOrderPart from "./backOrderPart";

export default {
  customer,
  common,
  purchaseOrder,
  vehicleAcquisition,
  inventory,
  invoice,
  user,
  login,
  accountTerms,
  otherCharge,
  role,
  xero,
  saleReport,
  catalog,
  partManagement,
  superAdminDashboard,
  priceList,
  backOrderPart,
};
