import moment from "moment";

const state = () => {
  return {
    selectedDates: [
      moment().format("YYYY-MM-DD"),
      moment().add(1, "days").format("YYYY-MM-DD"),
    ],
    filterData: {},
  };
};

const mutations = {
  SET_SELECTED_DATES: (state, dates) => {
    state.selectedDates = dates;
  },
  SET_CACHE_FILTER: (state, filterData) => {
    state.filterData = filterData;
  },
};

const actions = {
  setSelectedDates({ commit }, dates) {
    console.log("Commitdates", dates);
    commit("SET_SELECTED_DATES", dates);
  },
  setCacheFilter({ commit }, filterData) {
    commit("SET_CACHE_FILTER", filterData);
  },
};

const getters = {
  getSelectDates(state) {
    return state.selectedDates;
  },
  getCacheFiltered(state) {
    return state.filterData;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
