import router from "@/router";

const state = () => {
  return {
    filterList: {
      makeId: "",
      modelId: "",
      seriesId: "",
      search: "",
    },
    makeList: [],
    isLoading: false,
  };
};

const mutations = {
  SET_SELECTED_FILTER_LIST: (state, filterList) => {
    state.filterList = filterList;
  },
  SET_CLEAR_FILTER_LIST: (state, payload) => {
    state.filterList = payload;
  },
  SET_MAKE_LIST: (state, payload) => {
    state.makeList = payload;
  },
  SET_SEARCH_LOADING: (state, payload) => {
    state.isLoading = payload;
  },
};

const actions = {
  setSelectedFilterList({ commit }, filterList) {
    commit("SET_SELECTED_FILTER_LIST", filterList);
  },
  async setMakeList({ commit }, apiMake) {
    try {
      const result = await apiMake;

      const { code, data } = result;

      if (code === 200) {
        commit("SET_MAKE_LIST", data);
      }
    } catch (error) {
      throw new Error(error);
    }
  },
  setClearFilter({ commit }) {
    const payload = {
      makeId: "",
      modelId: "",
      seriesId: "",
      search: "",
    };
    commit("SET_CLEAR_FILTER_LIST", payload);
  },
  async globalSearchHandler(
    { state, commit, dispatch },
    { listWords, descWords, apiModel, apiSeries }
  ) {
    // ,
    commit("SET_SEARCH_LOADING", true);
    if (router.currentRoute.name !== "inventorylist") {
      router.push({ name: "inventorylist" });
    }
    let listModel = [];
    let listSeries = [];
    const makeIdSelected = state.filterList.makeId;
    const modelIdSelected = state.filterList.modelId;
    if (listWords.length > 0) {
      if (!state.filterList.makeId) {
        const make = state.makeList.find((make) =>
          make.name
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(listWords[0].toLowerCase())
        );
        if (!make) {
          await dispatch("setClearFilter");
          commit("SET_SEARCH_LOADING", false);
          commit("SET_SELECTED_FILTER_LIST", {
            ...state.filterList,
            search: listWords.join(" "),
          });
          return;
        }
        await dispatch("setClearFilter");
        if (descWords.length > 0) {
          commit("SET_SELECTED_FILTER_LIST", {
            ...state.filterList,
            search: descWords[0],
          });
        }
        commit("SET_SELECTED_FILTER_LIST", {
          ...state.filterList,
          makeId: make.id,
        });
        if (listWords[1]) {
          const modelResult = await apiModel({ makeId: make.id });
          listModel = modelResult.data;
          const model = listModel.find((model) =>
            model.name
              .toLowerCase()
              .replace(/\s+/g, "")
              .includes(listWords[1].toLowerCase())
          );
          if (!model) {
            commit("SET_SEARCH_LOADING", false);
            alert(`Model ${listWords[1]} not exits in ${make.name}`);
            return;
          }
          commit("SET_SELECTED_FILTER_LIST", {
            ...state.filterList,
            modelId: model.id,
          });
          if (listWords[2]) {
            const seriesResult = await apiSeries({
              makeId: make.id,
              modelId: model.id,
            });
            listSeries = seriesResult.data;
            const series = listSeries.find((series) =>
              series.name
                .toLowerCase()
                .replace(/\s+/g, "")
                .includes(listWords[2].toLowerCase())
            );
            if (!series) {
              commit("SET_SEARCH_LOADING", false);
              alert(
                `Series ${listWords[2]} not exits in ${make.name}-${model.name}`
              );
              return;
            }
            commit("SET_SELECTED_FILTER_LIST", {
              ...state.filterList,
              seriesId: series.id,
            });
          }
        }
      } else {
        if (!state.filterList.modelId) {
          const modelResult = await apiModel({ makeId: makeIdSelected });
          listModel = modelResult.data;
          const model = listModel.find((model) =>
            model.name
              .toLowerCase()
              .replace(/\s+/g, "")
              .includes(listWords[0].toLowerCase())
          );
          if (!model) {
            // alert(`Model ${listWords[0]} not exits in ${make.name}`);
            await dispatch("setClearFilter");
            await dispatch("globalSearchHandler", {
              listWords,
              descWords,
              apiModel,
              apiSeries,
            });
            return;
          }
          commit("SET_SELECTED_FILTER_LIST", {
            ...state.filterList,
            modelId: model.id,
          });
          if (listWords[1]) {
            const seriesResult = await apiSeries({
              makeId: makeIdSelected,
              modelId: model.id,
            });
            listSeries = seriesResult.data;
            const series = listSeries.find((series) =>
              series.name
                .toLowerCase()
                .replace(/\s+/g, "")
                .includes(listWords[1].toLowerCase())
            );
            if (!series) {
              await dispatch("setClearFilter");
              await dispatch("globalSearchHandler", {
                listWords,
                descWords,
                apiModel,
                apiSeries,
              });
              return;
            }
            commit("SET_SELECTED_FILTER_LIST", {
              ...state.filterList,
              seriesId: series.id,
            });
          }
        } else {
          if (listWords[0]) {
            const seriesResult = await apiSeries({
              makeId: makeIdSelected,
              modelId: modelIdSelected,
            });
            listSeries = seriesResult.data;
            const series = listSeries.find((series) =>
              series.name
                .toLowerCase()
                .replace(/\s+/g, "")
                .includes(listWords[0].toLowerCase())
            );
            if (!series) {
              await dispatch("setClearFilter");
              await dispatch("globalSearchHandler", {
                listWords,
                descWords,
                apiModel,
                apiSeries,
              });
              return;
            }
            commit("SET_SELECTED_FILTER_LIST", {
              ...state.filterList,
              seriesId: series.id,
            });
          }
        }
      }
    }

    commit("SET_SEARCH_LOADING", false);
  },
};

const getters = {
  getSelectedFilterList(state) {
    return state.filterList;
  },
  getMakeList(state) {
    return state.makeList;
  },
  getSearchLoading(state) {
    return state.isLoading;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
